// Library Imports
import React from 'react';
import Typography from '@material-ui/core/Typography';
// Asset Imports
import '../../assets/css/style.css';
const FAQPDF = require("../../assets/pdf/RCP_FAQs.pdf");
const TC = require("../../assets/pdf/T-C_RCP.pdf");
const POLICY = require("../../assets/pdf/Policies_RCP.pdf");

//! Unused Imports
// import FAQPDF from "../../assets/pdf/RCP_FAQs.pdf";
// import TC from "../../assets/pdf/T-C_RCP.pdf";
// import POLICY from "../../assets/pdf/Policies_RCP.pdf";
// import finxprivacypolicy from './finxprivacypolicy'
// import finxdisclaimer from './finxdisclaimer'
// import finxcookiespolicy from './finxcookiespolicy'
// import Intro from "../../assets/pdf/Instructions_and_User_Journey.pdf";

function Copyright() {
    return (
        <Typography variant="inherit" color="inherit" align="center">
            {' © '}
            {' '}
            {new Date().getFullYear()}
            {' All Right Reserved'}
            {'.'}
        </Typography>
    );
}
function finxfooter() {
    return (
        <div>
            <footer>
                <div className="container">
                    <div className="row">
                        <div className="col-md-3 col-sm-3 col-xs-12">
                            {/* <p className="text-center"><a href="#" target="_blank"> www.ExpertMFD.com</a></p> */}
                            <p className="text-center"><a href="https://ciel.co.in/" target="_blank"> www.ciel.co.in</a></p>
                        </div>
                        <div className="col-md-3 col-sm-3 col-xs-12">
                            <p className="text-center" style={{color: "#fff"}}> <Copyright />  </p>
                        </div>
                        <div className="col-md-6 col-sm-6 col-xs-12">
                            <p className="text-center">
                                <a href={TC} target="_blank" >Terms and Conditions |</a>
                                <a href={POLICY} target="_blank" >Privacy Policy | </a>
                                <a href={FAQPDF} target="_blank" >FAQs |</a>
                                <a href="https://finxpert.org/grievance-redressal/" target="_blank" >Grievance Redressal </a>
                                {/* <a href={Intro} target="_blank" >indroduction</a> */}
                            </p>
                        </div>
                    </div>
                </div>
            </footer>
            <noscript>Not seeing a <a href="https://www.scrolltotop.com/">Scroll to Top Button</a>? Go to our FAQ page for more info.</noscript>
        </div>
    );
}

export default finxfooter;
