import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";

import { red, lightBlue } from "@material-ui/core/colors";
import { info, infoNotification, errorNotification, error, warning, warningNotification, success, successNotification } from "../notification/notifications";
import $ from 'jquery';
import ReactPlayer from 'react-player';
import { LearnerAssessmentService } from "../../services/learnerAssessment.service";
import { CardMedia } from "@material-ui/core";
import CourseImage from "../../assets/images/course_logo.png";
import { RegisterUserService } from "../../services/registration.service";
import moment from "moment";
import { OrderService } from "../../services/order.service";
import { ProductService } from '../../services/product.service';
import { ReattemptTestService } from "../../services/reattemptTest.service"
import { updateStatus } from "../../services/preAssessment.Service"

const useStyles = makeStyles(theme => ({
    card: {
        maxWidth: 345
    },
    media: {
        height: 0,
        paddingTop: "16.25%" // 16:9
    },
    expand: {
        transform: "rotate(0deg)",
        marginLeft: "auto",
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest
        })
    },
    expandOpen: {
        transform: "rotate(180deg)"
    },
    avatar: {
        backgroundColor: red[500]
    }
}));

export default class MyProductAllExtract extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            checkout: [],
            productdetail: this.props.myproduct,
            personnelProfileData: this.props.personnelProfileData,
            isAvailableSubscription: true,
            isSubcribed: false,
            Prequisite: [],
            videoUrl: '',
            profilePercentage: 0,
            loading: false,
        };
    }

    componentDidMount() {
        //let profilePercent = localStorage.getItem('ProfilePercent') === undefined ? parseInt(0) : parseInt(localStorage.getItem('ProfilePercent'));
        let profilePercent = localStorage.getItem('profilecomlitionvalue') === undefined ? parseInt(0) : parseInt(localStorage.getItem('profilecomlitionvalue'));
        let productVideourl = this.props.myproduct.productVideourl ? this.props.myproduct.productVideourl : "https://www.youtube.com/embed/tgbNymZ7vqY"
        this.setState({ profilePercentage: profilePercent, videoUrl: productVideourl });




    }

    activateprod = () => {
        $(".prgbarbtn").on("click", function () {
            $(".prgbarbtn")
                .parent()
                .parent()
                .removeClass("active");
            $(this)
                .parent()
                .parent()
                .addClass("active");
        });
    };
    productSubscribe = () => {
        
        // if (this.state.profilePercentage > 60 && this.state.personnelProfileData.applicationStatus === "SUBMITTED") {
        //     let learnerParty =
        //         "learnerPartyID=" + localStorage.getItem("userid-Token");
        //     let selectedProd =
        //         "&productID=" + this.props.myproduct.productID;
        //     let queryprameters = learnerParty + selectedProd;
        //     LearnerAssessmentService.fetchLearnerPrequisiteByProductID(
        //         queryprameters,
        //         res1 => {
        //             if (res1.status === "success") {
        //                 let validationpass = true;
        //                 if(this.props.myproduct.productID === 203401){
        //                     //alert("true")
        //                     var data1 = localStorage.getItem("PersonnelData")
        //                     console.log("data1:::",JSON.parse(data1))
        //                     console.log("data1:::",JSON.parse(data1).responseObject)
        //                     let mailDataKYD  = []
        //                     let obj2 = {}
        //                     obj2['emailId'] = JSON.parse(data1).responseObject.emailID
        //                     obj2['userName'] = JSON.parse(data1).responseObject.firstName
        //                     obj2['templateId'] = "1980870"
        //                     obj2['status'] =  "NISM VA SUBSCRIBED"     
        //                     obj2['partyId'] = JSON.parse(data1).responseObject.learnerPartyID
        //                     obj2['mobileNumber'] = JSON.parse(data1).responseObject.mobileNo
        //                     obj2["learnerId"]=JSON.parse(data1).responseObject.learnerID
        //                     console.log("obj2 :::", obj2)
        //                     mailDataKYD.push(obj2)
        //                     this.setState({
        //                         mailDataKYD:mailDataKYD
        //                     });
        //                     RegisterUserService.sendKYDMail(this.state.mailDataKYD, res2 => {
                            
        //                     })
        //                     let smsData=[]
        //                     let sms = {}
        //                     sms['userName'] = JSON.parse(data1).responseObject.firstName
        //                     sms['templateId'] = "40040"
        //                     sms['status'] =  "NISM VA SUBSCRIBED"     
        //                     sms['partyId'] = JSON.parse(data1).responseObject.learnerPartyID
        //                     sms['mobileNumber'] = JSON.parse(data1).responseObject.mobileNo
                            
        //                     console.log("sms :::", sms)
        //                     smsData.push(sms)
        //                     this.setState({
        //                         smsData:smsData
        //                     });
        //                     console.log("smsData :::",smsData)
        //                     RegisterUserService.sendSms(smsData, res3 => {
                            
        //                 })

                            
        //                 }
        //                 let prerequisiteList = res1.responseListObject;
        //                 this.setState({ Prequisite: prerequisiteList });
        //                 this.state.Prequisite.map(element => {
        //                     if (
        //                         element.prodcutID !==
        //                         this.props.myproduct.productID
        //                     ) {

        //                         if (element.status !== "Record found" && validationpass) {
        //                             validationpass = false;
        //                             warning(
        //                                 "Please subcribe " +
        //                                 element.productEntity.productName +
        //                                 " First.",
        //                                 warningNotification
        //                             );
        //                         } else if (
        //                             element.status === "Record found" &&
        //                             validationpass
        //                         ) {
        //                             if (element.productEntity.productName !== 'FEAT') {
        //                                 validationpass = false;
        //                                 warning(
        //                                     "Please subscribe " +
        //                                     element.productEntity.productName +
        //                                     " First.",
        //                                     warningNotification
        //                                 );
        //                             }
        //                         }
        //                     } else if (
        //                         element.prodcutID ===
        //                         this.props.myproduct.productID
        //                     ) {
        //                         alert("hii")
        //                         console.log("this.state.Prequisite;;;;;",this.state.Prequisite)
        //                         if (this.props.myproduct.paymentID !== "0") {
        //                             if (validationpass) {
        //                                 if (element.status === "Record found") {
        //                                     validationpass = false;
        //                                     warning(
        //                                         "AAAA You have already applied for " +
        //                                         element.productEntity.productName +
        //                                         ".",
        //                                         warningNotification
        //                                     );
        //                                 } else if (element.status !== "Record found") {
        //                                     validationpass = true;
        //                                     //warning("Please complete " + element.productEntity.productName + " First.", warningNotification);
        //                                 }
        //                             }
        //                         }
        //                     }
        //                 });
        //                 if (validationpass) {
        //                     this.setState({ loading: true });
        //                     this.props.subscription(this.props.myproduct);
        //                 }
        //             } else if (res1.status === "fail") {
        //                 this.setState({ loading: false });
        //                 if (res1.reasonCode === "SM_11") {
        //                     window.location.href = "/";
        //                 } else {
        //                     error(res1.reasonText, errorNotification);
        //                 }
        //             }
        //         },
        //         err => {
        //             error(err, errorNotification);
        //         }
        //     );
        // } else {
        //     warning(
        //         //"Please Complete your profile first. Your profile score should be greater than 60% & the compulsory fields & documents should be added inorder to proceed. Only once you are able to submit your form, you can attempt FEAT.",
        //         "Please complete your profile. Your profile score should be greater than 60% & the compulsory fields & documents should be added in order to proceed. Only once you are able to submit your form. Only then can you undertake the course.",
        //         warningNotification
        //     );
        // }



        // sneha

        if (this.state.profilePercentage > 60 && this.state.personnelProfileData.applicationStatus === "SUBMITTED") {

            var data1 = localStorage.getItem("PersonnelData")
            let subscribeData =
            {
                "learnerPartyID": JSON.parse(data1).responseObject.learnerPartyID,
                //"attemptNumberGrade":"Make Payment",
                "attemptNumberGrade": this.props.myproduct.productName === "EUIN Application" || this.props.myproduct.productName === "EUIN" ? "FRESH EUIN Application"
                :this.props.myproduct.productName === "EUIN Mapping" ? "Initiate EUIN Mapping"
                :this.props.myproduct.productName === "EUIN Renewal" ? "Initiate EUIN Renewal"
                : "Make Payment",
                "isActive": "1",
                "isExternalProduct": "1",
                "paymentID": "0",
                "productEntity": {
                    "productID": this.props.myproduct.productID
                },
                "attemptNumber": "1",
                "buID": "2",
                "isScoreUpdated": "0"

            }
            console.log("subscribeData::", subscribeData)
            LearnerAssessmentService.subscribeProduct(subscribeData,
                (response1) => {
                    if (response1.status === 'success') {
// debugger
                        let productName = "productName=" + this.props.myproduct.productName;
                        ProductService.ProductByName(productName, (res) => {
                            if (res.status === "success") {
                                var data1 = localStorage.getItem("PersonnelData")

                                let grade = ""
                                if (this.props.myproduct.productName === "NISM VA Preparatory Course") {
                                    grade = "NISM VA SUBSCRIBE"
                                } else if (this.props.myproduct.productName === "EUIN Application" ||this.props.myproduct.productName === "EUIN") {
                                    grade = "EUIN SUBSCRIBE"
                                } else {
                                    grade = "SUBSCRIBE"
                                }

                                let acceptData = []
                                let obj = {
                                    "action": grade,
                                    "workflowId": JSON.parse(data1).responseObject.learnerID,
                                    "workflowName": "EUIN",
                                    "comment": ""
                                }
                                acceptData.push(obj)
                                if (acceptData.length > 0) {
                                    let workflowtemp = {
                                        "ExcecutionTracker": acceptData
                                    }
                                    updateStatus(workflowtemp, res1 => {

                                    })
                                }

                            }
                        });

                        if (this.props.myproduct.productName === "NISM VA Preparatory Course") {
                            var data1 = localStorage.getItem("PersonnelData")
                            let smsData = []
                            let sms = {}
                            sms['userName'] = JSON.parse(data1).responseObject.firstName
                            sms['templateId'] = "40040"
                            sms['status'] = "NISM VA SUBSCRIBED"
                            sms['partyId'] = JSON.parse(data1).responseObject.learnerPartyID
                            sms['mobileNumber'] = JSON.parse(data1).responseObject.mobileNo

                            console.log("sms :::", sms)
                            smsData.push(sms)
                            this.setState({
                                smsData: smsData
                            });
                            console.log("smsData :::", smsData)
                            RegisterUserService.sendSms(smsData, res3 => {

                            })

                        }

                        setTimeout(() => {
                            var data1 = localStorage.getItem("PersonnelData")
                            let data = []
                            var personaldetails = {
                                partyId: JSON.parse(data1).responseObject.learnerPartyID,
                                mobileNo: JSON.parse(data1).responseObject.mobileNo,
                                firstName: JSON.parse(data1).responseObject.firstName,
                                lastName: JSON.parse(data1).responseObject.lastName,
                                emailID: JSON.parse(data1).responseObject.emailID,
                                entryMode: 1,
                                dateOfBirth: JSON.parse(data1).responseObject.dob
                            };

                            var productdetails = {
                                productId: this.props.myproduct.productID,
                                productName: this.props.myproduct.productName,
                                productPrice: this.props.myproduct.productPrice,
                                partnerID: this.props.myproduct.partnerID
                            };
                            data.push(productdetails)

                            this.state.checkout.push({ personaldetails, data });

                            let checkoutd = "";
                            let orderItemListArray = [];
                            let orderItemList = {
                                itemID: 1,
                                productID: this.props.myproduct.productID,
                                couponApplied: "ZERO",
                                prodctPrice: this.props.myproduct.productPrice
                            };
                            orderItemListArray.push(orderItemList);
                            checkoutd = {
                                learnerPartyID: localStorage.getItem("userid-Token"),
                                orderPlacedOn: moment(new Date()).format("DD/MM/YYYY HH:mm:ss"), //'15/11/2018 15:18:33',
                                totalItemPrice: this.props.myproduct.productPrice,
                                totalOrderPrice: this.props.myproduct.productPrice,
                                paymentStatus: "Initiated",
                                orderItemList: orderItemListArray,
                                "currency": "INR"  // Field added for RazorPAy
                            };
                           
                            if (this.props.myproduct.productPrice > 0) {
                                OrderService.RazorPayInsertOrder(
                                    checkoutd,
                                    res => {
                                        if (res.status === "success") {
                                            localStorage.setItem("reload", "true");
                                            this.setState({ loading: false });
                                            this.props.history.push("/razorpay", {
                                                props: this.state.checkout,
                                                orderDetails: res.responseObject
                                            });
                                        } else if (res.status === "fail") {
                                            this.setState({ loading: false });
                                            if (res.reasonCode === "SM_11") {
                                                window.location.href = "/";
                                            } else {
                                                error(res.reasonText, errorNotification);
                                            }
                                        }
                                    },

                                    error => {
                                        console.log("Order Service ", error);
                                    }
                                );
                            } else {
                                window.location.reload();
                            }
                        }, 1500)
                    }
                })


        } else {
            warning(
                //"Please Complete your profile first. Your profile score should be greater than 60% & the compulsory fields & documents should be added inorder to proceed. Only once you are able to submit your form, you can attempt FEAT.",
                "Please complete your profile. Your profile score should be greater than 60% & the compulsory fields & documents should be added in order to proceed. You can undertake the course only after you have submitted your profile.",
                warningNotification
            );
        }        
    };

    showMyprod = () => {
        localStorage.setItem('showproductid', "progress-status1")
        // document.getElementById('progress-status').style.display = "block";
        
        document.getElementById('progress-status1').style.display = "block";
        
        document.getElementById('progress-status').style.display = "none";
        $(".course-footer").on("click", function () {
            $(".course-footer").removeClass("active");
            $(this).addClass("active");
        });
        if (this.state.profilePercentage > 60) {
            this.props.showMypreRequisite(this.props.myproduct, "New");
        } else {
            // warning("Please update your profile first. Your profile score should be greater then 60% to proceed.", warningNotification);
            warning(
                // "Please Complete your profile first. Your profile score should be greater than 60% & the compulsory fields & documents should be added inorder to proceed. Only once you are able to submit your form, you can attempt FEAT.",
                "Please submit your profile first. Your profile score should be greater than 60% to proceed.",
                warningNotification
            );
        }

    }
    render() {
        return (
            // <Card>
            <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 " >

                <div class="wrapper">
                    <div className="course">
                        <div class="product-header">

                            <div className="video">

                                <img src={CourseImage} width="100%" height="110px" />
                                {/* <CardMedia
                                    component="img"
                                    alt="Contemplative Reptile"
                                    height="110"
                                    image="/static/media/course_logo.710e9d53.png"
                                    title="Contemplative Reptile"
                                /> */}
                                {/* <ReactPlayer
                                    width="100%"
                                    height="110px"
                                    url={this.state.videoUrl}
                                    playing={false}
                                    controls /> */}
                            </div>
                            <div className="course-type">
                                <h5 className="course-name">
                                    E-Learning
              <span className="pull-right">English</span>
                                </h5>
                            </div>
                        </div>
                        <div class="content">
                            <div className="course-number" >
                                <h5 className="course-no">
                                {/* {this.props.myproduct.productName} */}
                                {(this.props.myproduct.productName === "NISM VA Preparatory Course" && this.props.myproduct.attemptNumberGrade === "Make Payment")? (
                        "NISM VA Exam Fees"
                      ) : this.props.myproduct.productName}
                                </h5>

                                <CardHeader className="CardHeader"

                                    //subheader={props.myproduct.productID.productCode}
                                    action={this.state.isAvailableSubscription ? <button className="btn-7" onClick={this.productSubscribe} >
                                        <a href=""
                                            data-keyboard="true" data-toggle="modal" data-target="#new-subscription"  >
                                            Subscribe</a></button> : null}
                                />
                            </div>
                            {/* <CardMedia
                className={classes.media}
                image="/static/images/cards/paella.jpg"
                title="Paella dish"
            /> */}
                            <div className="course-description">
                                <CardContent>
                                    <p>
                                        {
                                            this.props.myproduct.productDescription > 55 ?
                                                this.props.myproduct.productDescription
                                                // this.props.myproduct.productDescription.substring(0, 50) + " ...."
                                                : this.props.myproduct.productDescription
                                        }
                                    </p>
                                </CardContent>
                            </div>

                            <div className="course-rating">
                                <span>
                                    <i className="fa fa-star" aria-hidden="true"></i>
                                    <i className="fa fa-star" aria-hidden="true"></i>
                                    <i className="fa fa-star" aria-hidden="true"></i>
                                    <i className="fa fa-star" aria-hidden="true"></i>
                                    <i className="fa fa-star" aria-hidden="true"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div onClick={this.showMyprod} className="course-footer prgbarbtn" >
                        <div class="footer">
                            <span >
                                <i className="fa fa-user1" aria-hidden="true">
                                    {" "}

                                </i>
                            </span>
                            {/* <button type="button" class="btn-11 prgbarbtn" onClick={this.activateprod}><a href="#" ></a> Prerequisite </button> */}
                            <button type="button" class="btn-11 prgbarbtn" onClick={this.activateprod}><a href="#" ></a> Prerequisite </button>

                        </div>
                    </div>
                </div>
            </div>
            // </Card>
        );
    }
}
