import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  getProductPreRequisite,
  getProductPreRequisitePending,
  getProductPreRequisiteError
} from "../../reducer/productPreRequisiteReducer";
import {
  fetchProductPreRequisiteByParentProductId,
  fetchProductPreRequisiteByParentProduct,
  fetchProductDetailsByPartyId
} from "../../services/fetchLearnerDashboard.service";
import { LoginService } from "../../services/login.service";
import $ from "jquery";
import { Circle, Line } from "rc-progress";
import { ProductService } from "../../services/product.service";
import { CourserAndModuleService } from "../../services/courseAndModule.service";
import MyCouseModule from "./myCourseModule";
import MyFinalStatus from "./myfinalstatus";
// import { Steps } from "rsuite";
// import { Icon } from "rsuite";
//import '../../../node_modules/rsuite/dist/styles/rsuite-default.css';
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";

// const mapStateToProps = state => ({
//     productPreRequisite: getProductPreRequisite(state.productPreRequisiteReducer),
//     pendingProductPreRequisite: getProductPreRequisitePending(state.productPreRequisiteReducer),
//     errorProductPreRequisite: getProductPreRequisiteError(state.productPreRequisiteReducer)
// });

// const mapDispatchToProps = dispatch =>
//     bindActionCreators({
//         fetchProductPreRequisiteByParentProductId: fetchProductPreRequisiteByParentProductId,
//     },
//         dispatch
//     );

// const connectFunction = connect(mapStateToProps, mapDispatchToProps);

//const MyProgressPath = connectFunction(
class MyProgressPath extends React.Component {
  constructor(props) {
    super(props);
    //console.log("current progress path : ", props);

    this.state = {
      currentproduct: [],
      preRequisite: [],
      courseandmodule: [],
      showAllproductprerequisite: false,
      learningStatus: this.props?.mappedproductdetails[0]?.progressReport?.learningStatus,
    };
  }

  componentDidMount() {

    var self = this;
    // $("#root").on("mouseenter", ".course-footer", function () {
    //     // alert('click');
    //     //$(this).addClass("activetab")

    //     $(this).trigger("click");

    // });
  
      // document.getElementById('progress-tab').style.display = "none";
   
    

    LoginService.TokenUser(res => {
      localStorage.setItem("jwt-token", res.responseObject);
      //console.log("Get from session :" + localStorage.getItem('selectedproductprogress'));
      if (this.props.currentproduct) {
        this.setState({
          currentproduct: this.props.currentproduct.productEntity
            ? this.props.currentproduct.productEntity
            : this.props.currentproduct
        });
        let curData = this.props.currentproduct.productEntity
          ? this.props.currentproduct.productEntity
          : this.props.currentproduct
        let productId = curData.productID; //this.props.currentproduct.productEntity ? this.props.currentproduct.productEntity.productID : this.props.currentproduct.productID;
        let productdetails =
          this.props.CurrentKey === "New"
            ? this.props.allproductdetails
            : this.props.mappedproductdetails;
        ProductService.fetchProductPreRequisiteByParentProduct(
          "parentProductID=" + productId,
          res => {
            //console.log("My Progress Path landing ", res.responseListObject);

            if (res.status === "success") {
              //this.setState({ preRequisite: res.responseListObject });
              if (res.reasonText === "Record not found") {

                let pojoArray = [];
                let prod = this.props.currentproduct.productEntity
                  ? this.props.currentproduct.productEntity
                  : this.props.currentproduct;
                productdetails.forEach(element => {
                  let prodid = element.productEntity
                    ? element.productEntity.productID
                    : element.productID;
                  if (prodid === prod.productID) {
                    let pojo = {
                      productId: prod.productID,
                      productName: prod.productName,
                      productDescription: prod.productDescription,
                      productType: prod.productType,
                      productLink: prod.productLink,
                      parentProductID: prod.parentProductID,
                      sequenceCount: 0,
                      seqProductID: prod.productID,
                      scorePercent: element.isScoreUpdated >= 1 ? 100 : 0
                    };
                    pojoArray.push(pojo);
                    this.state.preRequisite.push(pojoArray);
                    //  this.state.preRequisite.push(FinalReq);
                    this.setState({
                      preRequisite: this.state.preRequisite
                    })
                  }
                });
              } else {
                let pojoArray = [];
                let prodid = [];

                for (
                  let index = 0;
                  index < res.responseListObject.length;
                  index++
                ) {
                  const element = res.responseListObject[index];
                  if (this.props.mappedproductdetails != null) {
                    this.props.mappedproductdetails.forEach(ele => {
                      let ispresent = false;

                      if (
                        element.seqProductID === ele.productEntity.productID
                      ) {
                        if (prodid.length > 0) {
                          ispresent = prodid.includes(
                            ele.productEntity.productID
                          );
                        }
                        prodid.push(ele.productEntity.productID);

                        if (!ispresent) {
                          let pojo = {
                            productId: element.seqProductID,
                            productName: ele.productEntity.productName,
                            productDescription:
                              ele.productEntity.productDescription,
                            productType: ele.productEntity.productType,
                            productLink: ele.productEntity.productLink,
                            parentProductID: element.parentProductID,
                            sequenceCount: element.sequenceCount,
                            seqProductID: element.seqProductID,
                            scorePercent: ele.isScoreUpdated >= 1 ? 100 : 0
                          };
                          pojoArray.push(pojo);
                          return;
                        }
                      }
                    });
                  } else {
                    this.props.allproductdetails.forEach(ele => {
                      let ispresent = false;

                      if (element.seqProductID === ele.productID) {
                        if (prodid.length > 0) {
                          ispresent = prodid.includes(ele.productID);
                        }
                        prodid.push(ele.productID);

                        if (!ispresent) {
                          let pojo = {
                            productId: element.seqProductID,
                            productName: ele.productName,
                            productDescription: ele.productDescription,
                            productType: ele.productType,
                            productLink: ele.productLink,
                            parentProductID: element.parentProductID,
                            sequenceCount: element.sequenceCount,
                            seqProductID: element.seqProductID,
                            scorePercent: ele.isScoreUpdated >= 1 ? 100 : 0
                          };
                          pojoArray.push(pojo);
                          return;
                        }
                      }
                    });
                  }
                }
                let ispresent = false;
                for (
                  let index = 0;
                  index < res.responseListObject.length;
                  index++
                ) {
                  const element = res.responseListObject[index];
                  pojoArray.forEach(ele => {
                    if (ele.productId !== element.seqProductID) {
                      if (prodid.length > 0) {
                        ispresent = prodid.includes(element.seqProductID);
                        if (!ispresent) {
                          let pojo = {
                            productId: element.seqProductID,
                            productName: element.seqProductID,
                            productDescription: "",
                            productType: "",
                            productLink: "",
                            parentProductID: element.parentProductID,
                            sequenceCount: element.sequenceCount,
                            seqProductID: element.seqProductID,
                            scorePercent: element.isScoreUpdated >= 1 ? 100 : 0
                          };
                          prodid.push(element.seqProductID);
                          pojoArray.push(pojo);
                          return;
                        }
                      }
                    }
                  });
                }
                let newpojo = [];
                for (
                  let index = 0;
                  index < res.responseListObject.length;
                  index++
                ) {
                  const element = res.responseListObject[index];
                  pojoArray.forEach(ele => {
                    if (element.seqProductID === ele.productId) {
                      let ab = {
                        productId: ele.productId,
                        productName: ele.productName,
                        productDescription: ele.productDescription,
                        productType: ele.productType,
                        productLink: ele.productLink,
                        parentProductID: ele.parentProductID,
                        sequenceCount: element.seqProductID,
                        seqProductID: ele.seqProductID,
                        scorePercent: ele.scorePercent
                      };
                      newpojo.push(ab);
                    }
                  });
                }
                let FinalReq = [];
                newpojo.forEach(element => {
                  this.props.allproductdetails.forEach(ele => {
                    if (element.productId === ele.productID) {
                      let ab = {
                        productId: element.productId,
                        productName: ele.productName,
                        productDescription: ele.productDescription,
                        productType: ele.productType,
                        productLink: ele.productLink,
                        parentProductID: element.parentProductID,
                        sequenceCount: element.seqProductID,
                        seqProductID: element.seqProductID,
                        scorePercent: element.scorePercent
                      };
                      FinalReq.push(ab);
                    }
                  });
                });
                //console.log("FinalReq", FinalReq);
                this.state.preRequisite.push(FinalReq);
                this.setState({
                  preRequisite: this.state.preRequisite
                })
                // this.state.preRequisite.push(FinalReq);
                console.log("state FinalReq222", this.state.preRequisite[0]);
              }
            }
          }
        );
      }
    });
  }

  hideshowMyprodNew = () => {
// alert()
// 
// let id=localStorage.getItem('showproductid')
// let id=localStorage.getItem('showproductidAll')
// console.log('@@@@@jkldklfjl  #### :',localStorage.getItem('showproductid')==="progress-status")
if ( localStorage.getItem('showproductid')==="progress-status") {
  document.getElementById('progress-status').style.display = "none";
}

if ( localStorage.getItem('showproductid')==="progress-status1") {
  document.getElementById('progress-status1').style.display = "none";
}
// else if(id==="progress-status"){

//   document.getElementById('progress-status').style.display = "none";
// }
    
    
    console.log(this.state)
    $(".course-footer ").removeClass('active');
    // $("#progress-statu ").removeClass('active');
    // $(".prgbarbtn ").removeClass('active')
    // localStorage.setItem("showAllproductprerequisite",false)
    // this.setState({
    //     showAllproductprerequisite: false,
    // });

  }
  showMypreRequisite = (product) => {

    this.state.currentAllProd.pop();
    this.state.currentAllProd.push(product);
    this.setState({
      showprogressbar: false,
      showAllproductprerequisite: true,
      currentKey: "New",
      currentAllProdkey: JSON.stringify(product.productID)
    });

  }
  activateprod = () => {
    this.setState({
      showMyprod: true,
      showprogressbar: true,
    });
    if ($('#QCButton').hasClass('background')) {
      $('#QCButton').removeClass('background');
    }
  }
  render() {
    //  alert(this.props.tempdata.length)
    // return this.state.preRequisite.length 
    return this.state.preRequisite.length > 0 ? (
      <React.Fragment>
        <section id="tabs" className="progress-tab">
          <div className="row">
            <div className="progress-tab-headings" id="nav-tab" role="tablist">
              <div className="col-md-11 col-sm-11 pull-left padding-0">
                {JSON.parse(localStorage.getItem("PersonnelData"))?.responseObject?.learnerEuinOption !== "Fresh EUIN" && (
                <a
                  className="tab-item tab-link active inner"
                  id="nav-home-tab"
                  data-toggle="tab"
                  href="#tab1"
                  role="tab"
                  aria-controls="nav-home"
                  aria-selected="true"
                >
                  <button id="QCButton" className="btn-10 background"> Qualification Criteria </button>
                </a>
                )}
                <a
                  className="tab-item tab-link inner"
                  id="nav-contact-tab"
                  data-toggle="tab"
                  href="#tab3"
                  role="tab"
                  aria-controls="nav-contact"
                  aria-selected="false"
                >
                  <button className="btn-10" onClick={this.activateprod} >Status </button>
                </a>

              </div>
              <button type="reset" class="close prgbarbtn" id="prgbarbtn" 
              aria-label="Close" onClick={this.hideshowMyprodNew}><span aria-hidden="true">×</span>
              </button>
              {/* <button type="reset" class="close prgbarbtn" id="pgbar" aria-label="Close" onclick="showDiv()"><span aria-hidden="true">×</span>
                </button> */}
              {/* <a
                                    className="tab-item tab-link"
                                    id="nav-profile-tab"
                                    data-toggle="tab"
                                    href="#tab2"
                                    role="tab"
                                    aria-controls="nav-profile"
                                    aria-selected="false"
                                > Course </a> */}
            </div>
            <div className="tab-content" id="nav-tabContent">
              <div
                className="tab-pane fade in active"
                id="tab1"
                role="tabpanel"
                aria-labelledby="nav-home-tab"
              >
                <div className="qc">
                  <div class="">
                    <div className="mt-10">
                    {JSON.parse(localStorage.getItem("PersonnelData"))?.responseObject?.learnerEuinOption !== "Fresh EUIN" && (
                      <div className="profile-graph-my-learning-path">
                        <div>
                          {/* <Steps current={""}>
                                                        {
                                                            this.state.preRequisite.length ? this.state.preRequisite[0].map(item => (

                                                                <Steps.Item title={item.productName} description={parseInt(item.scorePercent) == 100 ? "Finished" : "Pending"} />
                                                            )) : null
                                                        }

                                                    </Steps> */}
                          <span className="progressGraph">
                            {this.state.preRequisite.length
                              ? this.state.preRequisite[0].map((item, count) => (
                                <span className="progressProduct">
                                  {" "}
                                  {parseInt(item.scorePercent) === 100 ? (
                                    <span>
                                      <span className="progressIcon">
                                        <i
                                          className="fa fa-check-circle green1"
                                          aria-hidden="true"
                                        >
                                          {" "}
                                        </i>{" "}
                                        <strong className="elipsisApply">{item.productName}</strong>
                                        &nbsp;&nbsp;
                                        {this.state.preRequisite[0].length ===
                                          count + 1 ? (
                                            ""
                                          ) : (
                                            <i
                                              class="fa fa-long-arrow-right"
                                              aria-hidden="true"
                                            ></i>
                                          )}
                                      </span>

                                      <h5 className="ProgressStatus">
                                        {" "}
                                        {/* Finished{" "} */}
                                        {this.state.learningStatus || "Finished"}{" "}
                                      </h5>

                                    </span>
                                  ) : (
                                      <span>
                                        <span className="progressIcon">
                                          <i
                                            class="fa fa-circle gray"
                                            aria-hidden="true"
                                          ></i>
                                          <strong className="elipsisApply">{item.productName}</strong>
                                          &nbsp;&nbsp;
                                          {/* {this.state.preRequisite[0].length}
                                          {count } */}
                                          {this.state.preRequisite[0].length ===
                                            count + 1 ? (
                                              ""
                                            ) : (
                                              <i
                                                class="fa fa-long-arrow-right"
                                                aria-hidden="true"
                                              ></i>
                                            )}
                                        </span>

                                        <h5 className="ProgressStatus">
                                          {" "}
                                          {/* Pending{" "} */}
                                          {this.state.learningStatus || "Pending"}{" "}
                                        </h5>

                                      </span>
                                    )}
                                </span>
                              ))
                              : null}
                          </span>
                        </div>
                        {/* <Stepper>
                                                    {
                                                            this.state.preRequisite.length ? this.state.preRequisite[0].map(item => (
                                                            <Step title={item.productName} description={parseInt(item.scorePercent) == 100 ? "Finished" : "Pending"} />
                                                          
                                                        )) : null
                                                    }
                                                    </Stepper> */}

                        {this.state.preRequisite.length &&
                          this.state.currentproduct !== "" ? (
                            <React.Fragment>
                              <div>
                                <p className="">
                                  {this.state.currentproduct.productDescription}
                                </p>
                              </div>
                            </React.Fragment>
                          ) : null}
                      </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* <div
                                    className="tab-pane fade"
                                    id="tab2"
                                    role="tabpanel"
                                    aria-labelledby="nav-profile-tab"
                                >
                                    <div className="container">
                                        <div className="qc">
                                            {
											this.state.preRequisite.length ?
											<MyCouseModule {...this.props} />
											: ''}
                                        </div>
                                    </div>
                                </div> */}
              <div
                className="tab-pane fade"
                id="tab3"
                role="tabpanel"
                aria-labelledby="nav-contact-tab"
              >
                <div className="">
                  <div className="qc">
                    {/* {this.state.preRequisite.length &&
                      this.state.currentproduct !== "" ? (
                        <MyFinalStatus
                          preRequisite={this.state.preRequisite[0]}
                          {...this.props}
                        />
                      ) : null}{" "} */}
                    {
                      <MyFinalStatus
                        preRequisite={this.state.preRequisite[0]}
                        {...this.props}
                      />
                    }

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12"></div>
        </section>
      </React.Fragment >
    ) : null;
  }
}
//);
export default MyProgressPath;

/*   <div className="col-md-3 col-lg-3 col-sm-3 col-xs-3" id={item.productId}>

        <Circle
            percent={parseInt(item.scorePercent) == parseInt(100) ? item.scorePercent : 100}
            strokeWidth="6"
            strokeColor={parseInt(item.scorePercent) != parseInt(100) ? "Red" : "#7bc248"} >
        </Circle>
        <div class="row">
            <div>
                <span className="profile-completion-title">{item.productName} </span>
                <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
            </div>
        </div>
        {/* {item.scorePercent} * /}


        </div> */
